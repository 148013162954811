import logo from './logo.svg';
import './App.css';
import Header from "./Components/Header"
import MainContent from "./Components/MainContent"

function App() {

	return (
    <div className="App">
      <Header name={process.env.REACT_APP_NAME}/>
      <MainContent/>
    </div>
  );
}

export default App;
