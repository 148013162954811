import React from 'react';
import { Stepper, Step, StepLabel, StepContent } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import ChooseDateContent from "./ChooseDateContent";
import TimeSlotList from "./TimeSlotList";
import Button from '@mui/material/Button';
import axios from 'axios';

export default function MainContent() {

  const handleNext = () => {
    setCurrentStep((step) => step+1);
  }

  const [currentStep, setCurrentStep] = React.useState(0);
  const [chosenDate, setChosenDate] = React.useState(new Date());
  const [chosenSlot, setChosenSlot] = React.useState(new Date());
  const [availableSlots, setAvailableSlots] = React.useState([]);

  React.useEffect(async () => {
    const result = await axios(
      'slots.txt',
    );

    setAvailableSlots(result.data.slots.map(function (x) {
      // Adjust GMT to local time zone
      return new Date(Date.parse(x) - (new Date().getTimezoneOffset())*60*1000);
    }));
  },[]);



    return (
    <Stepper activeStep={currentStep} alternativeLabel orientation='vertical'>
        <Step>
          <StepLabel StepIconComponent={TodayIcon}>Choose a date</StepLabel>
          <StepContent>
              <ChooseDateContent setChosenDate={setChosenDate} chosenDate={chosenDate} nextButton={handleNext} availableSlots={availableSlots}/>
              <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >Continue</Button>
          </StepContent>
        </Step>
        <Step>
          <StepLabel StepIconComponent={QueryBuilderIcon}>Let's find a time slot!</StepLabel>
          <StepContent>
            <TimeSlotList availableSlots={availableSlots} chosenDate={chosenDate} nextButton={handleNext} setChosenSlot={setChosenSlot}/>
          </StepContent>
        </Step>
        <Step>
          <StepLabel StepIconComponent={InfoIcon}>Give me some details</StepLabel>
          <StepContent>Chosen time slot is {chosenSlot.toString()}</StepContent>
        </Step>
        <Step>
          <StepLabel StepIconComponent={CheckIcon}>Done</StepLabel>
          <StepContent>AAA</StepContent>
        </Step>
    </Stepper>
    );
}