export default function TimeSlotList(props) {

    function getDateTimeSlots(day) {
        return props.availableSlots.filter(x => {
            if ((x.getDate() === day.getDate()) &&
            (x.getMonth() === day.getMonth()) &&
            (x.getYear() === day.getYear())) {
                return true;
            } else {
                return false;
            }
        })
    }

    function onClickTimeSlot(timeSlot) {
        console.log("Clicked on time slot " + timeSlot.toString());
        props.setChosenSlot(() => timeSlot);
        props.nextButton();
    }
    return (
        <div className="TimeSlotList--container">
            <div className="TimeSlotList--header">For {props.chosenDate.toDateString()}:</div>
        <ul className="TimeSlotList--list">
            {getDateTimeSlots(props.chosenDate).map(item => (
            <li className="TimeSlotList--item" key={item} onClick={() => onClickTimeSlot(item)}>
                {item.getHours().toString().padStart(2,'0')}:{item.getMinutes().toString().padStart(2,'0')}
            </li>
            ))}
        </ul>
        </div>
    );
}