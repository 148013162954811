import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function ChooseDateContent(props) {
    
    const ONE_DAY_MILISECONDS = 24*60*60*1000;
    const now = new Date();
    const tommorow = new Date(+now + ONE_DAY_MILISECONDS);
    const endDate = new Date(+now + process.env.REACT_APP_MAX_NUM_DAYS*ONE_DAY_MILISECONDS);

    function onChangeDate(event) {
        console.log(event);
        console.log(process.env);
        props.setChosenDate(event);
        props.nextButton();
    }

    function isDateDisabled({activeStartDate, date, view }) {
        // console.log(activeStartDate);
        // console.log(date);
        // console.log(view);
        if (props.availableSlots.some(function (slot) {
            let dateSlot = new Date(Date.parse(slot));
/* 
            console.log("DateSlot: " + dateSlot.getDate()  + "/" + dateSlot.getMonth() + "/" + dateSlot.getYear());
            console.log("Date: " + date.getDate()  + "/" + date.getMonth() + "/" + date.getYear()); */

            if ((dateSlot.getDate() === date.getDate()) && 
            (dateSlot.getMonth() === date.getMonth()) && 
            (dateSlot.getYear() === date.getYear())) {
                // We have an available spot in the specific date, return true for the entire date

                return true;
            }
        })) {
            // If there are some slots on this date, return false for disabled
            return false;
        }

        return true;
    }

    return (
        <div className='ChooseDateContent--container'>
            <Calendar 
            onChange={onChangeDate} 
            minDate={tommorow}
            maxDate={endDate} 
            minDetail='month'
            defaultValue={tommorow}
            calendarType='Hebrew'
            onClickDay={props.handleNext}
            tileDisabled={isDateDisabled}></Calendar>
        </div>
    );
}